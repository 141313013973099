<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
        !hideOrganization
      "
    >
      <organization-selector
        :organization="cafeteria.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            cafeteria.organization.id = organizationId;
            cafeteria.establishment.id = null;
            onFormChanged();
          }
        "
        :disabled="!!cafeteria.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <!-- establishment -->
    <div class="row" v-if="cafeteria.organization.id != null">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ESTABLISHMENT')} (*)`"
          :placeholder="$t('COMMON.ESTABLISHMENT')"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
        >
          <establishment-selector
            :establishment="cafeteria.establishment?.id"
            :filterable="true"
            :showAll="false"
            :filterOrganization="cafeteria.organization.id"
            @establishmentChanged="
              (establishmentId) => {
                cafeteria.establishment.id = establishmentId;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.establishment" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.NAME')} (*)`"
          :required="true"
          v-model="cafeteria.name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.name" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('CAFETERIAS.SERVICE_TYPE')} (*)`"
          :placeholder="$t('CAFETERIAS.SERVICE_TYPE')"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
          <el-select
            :name="`${$t('CAFETERIAS.SERVICE_TYPE')}`"
            :placeholder="$t('CAFETERIAS.SERVICE_TYPE')"
            v-model="cafeteria.service_type"
          >
            <el-option
              v-for="(service, key) in SERVICE_TYPES"
              :key="key"
              :value="service"
              :label="$t('CAFETERIAS.SERVICE_TYPE_' + service)"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.service_type" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('CAFETERIAS.OPERATION_TYPE')} (*)`"
          :placeholder="$t('CAFETERIAS.OPERATION_TYPE')"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
          <el-select
            :name="`${$t('CAFETERIAS.OPERATION_TYPE')}`"
            :placeholder="$t('CAFETERIAS.OPERATION_TYPE')"
            v-model="cafeteria.operation_type"
            @change="cafeteriaOperationChange"
          >
            <el-option
              v-for="(operation, key) in OPERATION_TYPES"
              :key="key"
              :value="operation"
              :label="$t('CAFETERIAS.OPERATION_TYPE_' + operation)"
            />
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.operation_type" />
      </div>
    </div>

    <div
      class="row"
      v-if="cafeteria.operation_type === OPERATION_TYPE_SATELLITE"
    >
      <div class="col">
        <base-input
          :label="`${$t('CAFETERIAS.RELATED_PRODUCTION_CAFETERIA')} (*)`"
          :placeholder="$t('CAFETERIAS.RELATED_PRODUCTION_CAFETERIA')"
          @change="
            () => {
              onFormChanged();
            }
          "
        >
          <cafeteria-selector
            :filterable="true"
            :showAll="false"
            :filterOperationType="OPERATION_TYPE_PRODUCTION"
            :filterOrganization="cafeteria.organization.id"
            :cafeteria="cafeteria?.productionCafeteria?.id"
            @cafeteriaChanged="
              (cafeteriaSelected) => {
                cafeteria.productionCafeteria.id = cafeteriaSelected;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.productionCafeteria" />
      </div>
    </div>

    <base-checkbox
      v-model="cafeteria.has_warehouse"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("COMMON.HAS_WAREHOUSE") }}
      </span>
    </base-checkbox>

    <base-checkbox
      v-model="cafeteria.has_folder"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("COMMON.HAS_FOLDER") }}
      </span>
    </base-checkbox>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          cafeteria.id
            ? $t("CAFETERIAS.EDIT_CAFETERIA")
            : $t("CAFETERIAS.ADD_CAFETERIA")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CafeteriaSelector from "@/components/CafeteriaSelector";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import EstablishmentSelector from "@/components/EstablishmentSelector.vue";
import { SERVICE_TYPES } from "@/constants/cafeteria";
const {
  OPERATION_TYPES,
  OPERATION_TYPE_SATELLITE,
  OPERATION_TYPE_PRODUCTION,
} = require("@/constants/cafeteria");

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    CafeteriaSelector,
    ValidationError,
    OrganizationSelector,
    EstablishmentSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: [
    "cafeteriaData",
    "formErrors",
    "loading",
    "hideOrganization",
    "hideSubmit",
  ],

  data() {
    const me = this.$store.getters["profile/me"];
    const cafeteriaData = { ...this.cafeteriaData };
    if (this.$currentUserIsLevelOrganization()) {
      cafeteriaData.organization.id = me.organization.id;
    }
    return {
      cafeteria: cafeteriaData,
      SERVICE_TYPES,
      OPERATION_TYPES,
      OPERATION_TYPE_SATELLITE,
      OPERATION_TYPE_PRODUCTION,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      if (this.cafeteria.operation_type !== OPERATION_TYPE_SATELLITE) {
        delete this.cafeteria.productionCafeteria;
      }
      const cafeteriaData = cloneDeep(this.cafeteria);
      this.$emit("cafeteriaSubmitted", cafeteriaData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    cafeteriaOperationChange() {
      if (this.cafeteria.operation_type !== OPERATION_TYPE_SATELLITE) {
        this.cafeteria.productionCafeteria.id = null;
      }
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    cafeteriaData(cafeteriaData) {
      if (cafeteriaData) {
        cafeteriaData = {
          ...cafeteriaData,
          establishment: cafeteriaData?.establishment?.id
            ? cafeteriaData?.establishment
            : { type: "establishments", id: null },
          productionCafeteria: cafeteriaData?.productionCafeteria?.id
            ? cafeteriaData?.productionCafeteria
            : this.cafeteria?.productionCafeteria,
        };
        this.cafeteria = {
          ...this.cafeteria,
          ...cloneDeep(cafeteriaData),
        };
      }
    },
  },
};
</script>

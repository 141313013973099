export default {
  type: "cafeterias",
  name: null,
  service_type: "",
  operation_type: "",
  has_warehouse: false,
  has_folder: true,
  relationshipNames: ["organization", "establishment", "productionCafeteria"],
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  productionCafeteria: {
    type: "cafeterias",
    id: null,
  },
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
      !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.cafeteria.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.cafeteria.id},on:{"organizationChanged":(organizationId) => {
          _vm.cafeteria.organization.id = organizationId;
          _vm.cafeteria.establishment.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.cafeteria.organization.id != null)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ESTABLISHMENT')} (*)`,"placeholder":_vm.$t('COMMON.ESTABLISHMENT')}},[_c('establishment-selector',{attrs:{"establishment":_vm.cafeteria.establishment?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.cafeteria.organization.id},on:{"establishmentChanged":(establishmentId) => {
              _vm.cafeteria.establishment.id = establishmentId;
              _vm.onFormChanged();
            }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.establishment}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"required":true},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.cafeteria.name),callback:function ($$v) {_vm.$set(_vm.cafeteria, "name", $$v)},expression:"cafeteria.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('CAFETERIAS.SERVICE_TYPE')} (*)`,"placeholder":_vm.$t('CAFETERIAS.SERVICE_TYPE')},on:{"change":() => {
            _vm.onFormChanged();
          }}},[_c('el-select',{attrs:{"name":`${_vm.$t('CAFETERIAS.SERVICE_TYPE')}`,"placeholder":_vm.$t('CAFETERIAS.SERVICE_TYPE')},model:{value:(_vm.cafeteria.service_type),callback:function ($$v) {_vm.$set(_vm.cafeteria, "service_type", $$v)},expression:"cafeteria.service_type"}},_vm._l((_vm.SERVICE_TYPES),function(service,key){return _c('el-option',{key:key,attrs:{"value":service,"label":_vm.$t('CAFETERIAS.SERVICE_TYPE_' + service)}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.service_type}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('CAFETERIAS.OPERATION_TYPE')} (*)`,"placeholder":_vm.$t('CAFETERIAS.OPERATION_TYPE')},on:{"change":() => {
            _vm.onFormChanged();
          }}},[_c('el-select',{attrs:{"name":`${_vm.$t('CAFETERIAS.OPERATION_TYPE')}`,"placeholder":_vm.$t('CAFETERIAS.OPERATION_TYPE')},on:{"change":_vm.cafeteriaOperationChange},model:{value:(_vm.cafeteria.operation_type),callback:function ($$v) {_vm.$set(_vm.cafeteria, "operation_type", $$v)},expression:"cafeteria.operation_type"}},_vm._l((_vm.OPERATION_TYPES),function(operation,key){return _c('el-option',{key:key,attrs:{"value":operation,"label":_vm.$t('CAFETERIAS.OPERATION_TYPE_' + operation)}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.operation_type}})],1)]),(_vm.cafeteria.operation_type === _vm.OPERATION_TYPE_SATELLITE)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":`${_vm.$t('CAFETERIAS.RELATED_PRODUCTION_CAFETERIA')} (*)`,"placeholder":_vm.$t('CAFETERIAS.RELATED_PRODUCTION_CAFETERIA')},on:{"change":() => {
            _vm.onFormChanged();
          }}},[_c('cafeteria-selector',{attrs:{"filterable":true,"showAll":false,"filterOperationType":_vm.OPERATION_TYPE_PRODUCTION,"filterOrganization":_vm.cafeteria.organization.id,"cafeteria":_vm.cafeteria?.productionCafeteria?.id},on:{"cafeteriaChanged":(cafeteriaSelected) => {
              _vm.cafeteria.productionCafeteria.id = cafeteriaSelected;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.productionCafeteria}})],1)]):_vm._e(),_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.cafeteria.has_warehouse),callback:function ($$v) {_vm.$set(_vm.cafeteria, "has_warehouse", $$v)},expression:"cafeteria.has_warehouse"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.HAS_WAREHOUSE"))+" ")])]),_c('base-checkbox',{staticClass:"mb-3",on:{"change":() => {
        _vm.onFormChanged();
      }},model:{value:(_vm.cafeteria.has_folder),callback:function ($$v) {_vm.$set(_vm.cafeteria, "has_folder", $$v)},expression:"cafeteria.has_folder"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.HAS_FOLDER"))+" ")])]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.cafeteria.id ? _vm.$t("CAFETERIAS.EDIT_CAFETERIA") : _vm.$t("CAFETERIAS.ADD_CAFETERIA"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
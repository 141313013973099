export const SERVICE_TYPE_REGULAR = "REGULAR";
export const SERVICE_TYPE_WITH_SERVICE = "WITH_SERVICE";
export const SERVICE_TYPE_WITHOUT_SERVICE = "WITHOUT_SERVICE";
export const SERVICE_TYPE_ONLINE_ORDER = "ONLINE_ORDER";

export const SERVICE_TYPES = [
  SERVICE_TYPE_REGULAR,
  SERVICE_TYPE_WITHOUT_SERVICE,
  SERVICE_TYPE_WITH_SERVICE,
  SERVICE_TYPE_ONLINE_ORDER,
];

export const OPERATION_TYPE_PRODUCTION = "PRODUCTION";
export const OPERATION_TYPE_SATELLITE = "SATELLITE";
export const OPERATION_TYPE_AUTONOMOUS = "AUTONOMOUS";

export const OPERATION_TYPES = [
  OPERATION_TYPE_PRODUCTION,
  OPERATION_TYPE_SATELLITE,
  OPERATION_TYPE_AUTONOMOUS,
];
